<template>
  <div class="flix-progress" v-show="percent">
    <div class="flix-progress-bar" :class="{'flix-progress-bar-striped-2': percent != 100}" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" :style="{width: percent+'%'}">
      <span class="sr-only">{{ percent }}%</span>
    </div>
    <div class="flix-progress-bar flix-progress-bar-warning" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" :style="{width: 100 - percent+'%'}"></div>
  </div>
</template>
<script>
export default {
  props: { percent: Number }
}
</script>
<style lang="sass" scoped>
  .flix-progress-bar-warning
    background-color: #f0ad4e
</style>
